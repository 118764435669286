@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.services-container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 40px;
}

.services-container .box{
    position: relative;
    width: 350px;
    padding: 40px;
    background-color: #FFF;
    box-shadow: 0 5px 15px rgba(0,0,0,.1);
    border-radius: 4px;
    margin: 20px;
    box-sizing: border-box;
    overflow-y: auto;
    text-align: center;
    direction: rtl;
    max-height: 380px;
    height: 380px;
}

/* width */
.services-container .box::-webkit-scrollbar {
    width: 5px;
}
  
/* Track */
.services-container .box::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
.services-container .box::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
.services-container .box::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.services-container .box .icon{
    position: relative;
    width: 80px;
    height: 80px;
    color: #fff;
    background-color: black;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 0 auto;
    border-radius: 50%;
    font-size: 40px;
    font-weight: 700;
    transition: 0.5s;
}

.services-container .box .icon{
    box-shadow: 0 0 0 0 rgb(41, 171, 226);
    background-color: rgb(41, 171, 226);
}

.services-container .box:hover .icon{
    box-shadow: 0 0 0 400px rgb(41, 171, 226);
}

.services-container .box .content{
    position: relative;
    z-index: 1;
    transition: 0.5s;
    direction: rtl;
}

.services-container .box:hover .content{
    color: #fff;
}

.portfolio-area{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 40px;
}

.portfolio-area .box{
    position: relative;
    width: 350px;
    background-color: #FFF;
    box-shadow: 0 5px 15px rgba(0,0,0,.1);
    border-radius: 4px;
    margin: 20px;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;
    direction: rtl;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    transition: 0.5s;
    cursor: pointer;
}

.portfolio-area .box:hover{
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    transition: 0.5s;
}

.portfolio-area .box .content{
    position: relative;
    z-index: 1;
    transition: 0.5s;
    direction: rtl;
    height: 200px;
    max-height: 200px;
    overflow-y: auto;
}

.portfolio-area .box .content h4{
    font-size: 14px;
    color: rgb(41, 171, 226);
}

.portfolio-area .box img{
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.contact-form-container{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 40px;
}

.contact-form{
    width: 1150px;
    padding: 50px 80px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    transition: all 0.5s ease;
}

.contact-form .contact{
    margin-top: 25px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    transition: all 0.5s ease;
}

.contact-form .contact .map{
    width: 400px;
    margin-right: 35px;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    border: 1px dashed rgb(41, 171, 226);
    padding-top: 20px;
}

.contact-form .contact .map iframe{
    width: 100%;
    height: 300px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.contact-form .contact .form{
    width: 525px;
    transition: all 0.5s ease;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
}

.contact-form .contact .form input,
.contact-form .contact .form textarea,
.as-input {
    width: 100%;
    padding: 15px 10px;
    border: 1px solid #dadce0;
    border-radius: 5px;
    outline: none;
    margin-bottom: 20px;
    transition: 0.3s;
    background-color: #f8f9fa;
}

.contact-form .contact .form input:focus,
.contact-form .contact .form textarea:focus,
.as-input:focus{
    border: 1px solid rgb(41, 171, 226);
    box-shadow: inset 1px 1px 0 rgb(41, 171, 226),
    inset -1px -1px 0 rgb(41, 171, 226);
}

.contact-form .contact .form textarea{
    height: 90px;
    max-width: 100%;
    resize: none;
}

.contact-form .contact .form .submit-area{
    position: relative;
}

.contact-form .contact .form .submit-area .submit-button{
    display: inline-block;
    width: 125px;
    padding: 10px 20px;
    border-radius: 1px;
    cursor: pointer;
    background-color: rgb(41, 171, 226);
    border: none;
    color: #FFF;
    font-size: 14px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.1s ease;
    text-align: right;
}

.contact-form .contact .form .submit-area .submit-button:hover{
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.25);
}

.contact-form .contact .form .submit-area i{
    position: absolute;
    left: 10px;
    top: 15px;
    color: #FFF;
    font-size: 13px;
}

.contact-info-item{
    text-align: center;
}

.contact-info-item label{
    margin: 0 3px;
    direction: rtl;
}

.contact-info-title{
    margin-bottom: 7px !important;
    font-size: 20px;
    color: rgb(41, 171, 226);
}

.contact-info-item i{
    margin-left: 10px;
}

.contact-info-value{
    margin-bottom: 30px !important;
    color: rgba(41, 171, 226, 0.7);
}


@media (max-width: 1200px){
    .contact-form{
        width: 960px;
        padding: 50px 60px;
    }

    .contact-form .contact .map{
        width: 320px;
    }

    .contact-form .contact .form{
        width: 485px;
    }
}

@media (max-width: 992px){
    .contact-form{
        width: 710px;
        height: auto;
    }

    .contact-form .contact{
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .contact-form .contact .map{
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
    }

    .contact-form .contact .form{
        width: 100%;
    }
}

@media (max-width: 768px){
    .contact-form{
        width: 100%;
    }
}


.contact-form .contact .form .label{
    background-color: #f8f9fa;
    color: #6b6b6b;
    font-size: 15px;
    font-weight: 300;
    margin: 0 8px;
    padding: 0 5px;
    position: absolute;
    right: 0;
    transition: all 0.5s cubic-bezier(0.50, 1.35, 0.50, 1.35);
    direction: rtl;
}

.contact-form .contact.apply-job-container .form .label {
    right: unset !important;
}

.contact-form .contact .form .email-text,
.contact-form .contact .form .subject-text{
    -webkit-transform: translateY(-56px);
            transform: translateY(-56px);
}

.contact-form .contact .form .help-text{
    -webkit-transform: translateY(-112px);
            transform: translateY(-112px);
}

.contact-form .contact .form input:focus + .label,
.contact-form .contact .form input:valid + .label,
.contact-form .contact .form textarea:focus + .label,
.contact-form .contact .form textarea:valid + .label{
    font-size: 13px;
    font-weight: 600;
    color: rgb(41, 171, 226);
}

.contact-form .contact .form input:focus + .email-text,
.contact-form .contact .form input:valid + .email-text,
.contact-form .contact .form input:focus + .subject-text,
.contact-form .contact .form input:valid + .subject-text{
    -webkit-transform: translateY(-80px);
            transform: translateY(-80px);
}

.contact-form .contact .form textarea:focus + .help-text,
.contact-form .contact .form textarea:valid + .help-text{
    -webkit-transform: translateY(-123px);
            transform: translateY(-123px);
}

.form label{
    display: unset !important;
    margin-bottom: unset !important;
}
.careers-container {
    margin-top: 40px;
}

.job-container-item {
    margin: 60px 40px;
    text-align: center;
}

.job-container-item span {
    text-decoration: underline;
    color: cornflowerblue;
    cursor: pointer;
}

.careers-content .job-title{
    margin-bottom: 20px;
    margin-top: 20px;
}

.job-item-title,
.job-item-description,
.details-ul li {
    text-align: left;
}

.job-item-title {
    margin-top: 40px;
}

.details-ul {
    padding-left: 15px;
}

.apply-job-container {
    margin-top: 50px;
}
body, html, *{
    margin: 0;
    padding: 0;
}

body, html, *:not(i){
    font-family: 'Rubik', sans-serif !important;   
    scroll-behavior: smooth;
}

.banner-container{
    background-image: url(/static/media/team_work_banner.837b14b0.jpg);
    width: 100%;
    height: 700px;
    background-size: 100% 100%;
    background-position: center;
    object-fit: cover;
    object-position: center;
}

.overlay-banner-container{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.6);
}

/* Style the header */
.header {
    /* padding: 10px 16px; */
    /* background: #555; */
    color: #f1f1f1;
    z-index: 99;
}

.transparent-bg{
    background-color: rgba(0,0,0,0);
}

/* Page content */
.content {
    padding: 16px;
}

/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #212529;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
    padding-top: 102px;
}

.dir-rtl{
    direction: rtl !important;
}

.pointer{
    cursor: pointer;
}

.color-primary{
    color: rgb(41, 171, 226) !important;
}

.navbar-menu-container{
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
}

.nav-link{
    color: white;
}

.nav-link.active,
.nav-link:hover{
    color: rgb(41, 171, 226) !important;
}

.header-info-container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: calc(100% - 78.8px);
    direction: rtl;
}

.header-info-container label{
    color: white;
    font-size: 33px !important;
}

.header-info-container label.bold{
    font-weight: 600;
}

.btn-header{
    background-color: rgb(41, 171, 226);
    color: #FFF;
    margin-top: 20px;
    padding: 8px 30px;
    border-radius: 4px;
    text-decoration: none;
    font-size: 22px;
}

.btn-header:hover{
    text-decoration: none !important;
    color: #FFF;
    background-color: rgba(41, 171, 226, 0.89);
}

.section-container{
    width: 100%;
    padding: 90px 0;
}

.bg-white{
    background-color: #fff !important;
}

.bg-light{
    background-color: #f8f9fa !important;
}

.section-title{
    margin-top: 20px;
    font-size: 40px;
    text-align: center;
}

.section-sub-title{
    margin-top: 20px;
    font-size: 26px;
    text-align: center;
}

.section-description{
    margin-top: 30px;
    font-size: 20px;
    max-width: 80%;
    line-height: 1.5;
}

.modal-img{
    width: 350px;
    
}

.modal-body{
    text-align: center !important;
}

@media (max-width: 991.5px){
    .navbar-menu-container{
        -webkit-flex-direction: column !important;
                flex-direction: column !important;
    }

    .nav-link{
        text-align: center !important;
    }
}

@media (max-width: 790px){
    .header-info-container label{
        font-size: 24px !important;
    }

    .btn-header{
        font-size: 17px !important;
    }

    .section-title{
        font-size: 30px;
    }

    .section-sub-title{
        font-size: 20px;
    }

    .section-description{
        font-size: 16px;
    }

    .services-container .box .content h3{
        font-size: 30px;
    }
}

@media (max-width: 562px){
    .header-info-container label{
        font-size: 16px !important;
    }

    .btn-header{
        font-size: 14px !important;
    }

    .banner-container{
        height: 450px;
    }

    .section-title{
        font-size: 20px;
    }

    .section-sub-title{
        font-size: 17px;
    }

    .section-description{
        font-size: 14px;
    }

    .services-container .box .content h3{
        font-size: 20px;
    }
}
