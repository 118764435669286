.careers-container {
    margin-top: 40px;
}

.job-container-item {
    margin: 60px 40px;
    text-align: center;
}

.job-container-item span {
    text-decoration: underline;
    color: cornflowerblue;
    cursor: pointer;
}

.careers-content .job-title{
    margin-bottom: 20px;
    margin-top: 20px;
}

.job-item-title,
.job-item-description,
.details-ul li {
    text-align: left;
}

.job-item-title {
    margin-top: 40px;
}

.details-ul {
    padding-left: 15px;
}

.apply-job-container {
    margin-top: 50px;
}