@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap');

body, html, *{
    margin: 0;
    padding: 0;
}

body, html, *:not(i){
    font-family: 'Rubik', sans-serif !important;   
    scroll-behavior: smooth;
}

.banner-container{
    background-image: url('../images/team_work_banner.jpg');
    width: 100%;
    height: 700px;
    background-size: 100% 100%;
    background-position: center;
    object-fit: cover;
    object-position: center;
}

.overlay-banner-container{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.6);
}

/* Style the header */
.header {
    /* padding: 10px 16px; */
    /* background: #555; */
    color: #f1f1f1;
    z-index: 99;
}

.transparent-bg{
    background-color: rgba(0,0,0,0);
}

/* Page content */
.content {
    padding: 16px;
}

/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #212529;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
    padding-top: 102px;
}

.dir-rtl{
    direction: rtl !important;
}

.pointer{
    cursor: pointer;
}

.color-primary{
    color: rgb(41, 171, 226) !important;
}

.navbar-menu-container{
    flex-direction: row-reverse !important;
}

.nav-link{
    color: white;
}

.nav-link.active,
.nav-link:hover{
    color: rgb(41, 171, 226) !important;
}

.header-info-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 78.8px);
    direction: rtl;
}

.header-info-container label{
    color: white;
    font-size: 33px !important;
}

.header-info-container label.bold{
    font-weight: 600;
}

.btn-header{
    background-color: rgb(41, 171, 226);
    color: #FFF;
    margin-top: 20px;
    padding: 8px 30px;
    border-radius: 4px;
    text-decoration: none;
    font-size: 22px;
}

.btn-header:hover{
    text-decoration: none !important;
    color: #FFF;
    background-color: rgba(41, 171, 226, 0.89);
}

.section-container{
    width: 100%;
    padding: 90px 0;
}

.bg-white{
    background-color: #fff !important;
}

.bg-light{
    background-color: #f8f9fa !important;
}

.section-title{
    margin-top: 20px;
    font-size: 40px;
    text-align: center;
}

.section-sub-title{
    margin-top: 20px;
    font-size: 26px;
    text-align: center;
}

.section-description{
    margin-top: 30px;
    font-size: 20px;
    max-width: 80%;
    line-height: 1.5;
}

.modal-img{
    width: 350px;
    
}

.modal-body{
    text-align: center !important;
}

@media (max-width: 991.5px){
    .navbar-menu-container{
        flex-direction: column !important;
    }

    .nav-link{
        text-align: center !important;
    }
}

@media (max-width: 790px){
    .header-info-container label{
        font-size: 24px !important;
    }

    .btn-header{
        font-size: 17px !important;
    }

    .section-title{
        font-size: 30px;
    }

    .section-sub-title{
        font-size: 20px;
    }

    .section-description{
        font-size: 16px;
    }

    .services-container .box .content h3{
        font-size: 30px;
    }
}

@media (max-width: 562px){
    .header-info-container label{
        font-size: 16px !important;
    }

    .btn-header{
        font-size: 14px !important;
    }

    .banner-container{
        height: 450px;
    }

    .section-title{
        font-size: 20px;
    }

    .section-sub-title{
        font-size: 17px;
    }

    .section-description{
        font-size: 14px;
    }

    .services-container .box .content h3{
        font-size: 20px;
    }
}