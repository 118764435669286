
.services-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;
}

.services-container .box{
    position: relative;
    width: 350px;
    padding: 40px;
    background-color: #FFF;
    box-shadow: 0 5px 15px rgba(0,0,0,.1);
    border-radius: 4px;
    margin: 20px;
    box-sizing: border-box;
    overflow-y: auto;
    text-align: center;
    direction: rtl;
    max-height: 380px;
    height: 380px;
}

/* width */
.services-container .box::-webkit-scrollbar {
    width: 5px;
}
  
/* Track */
.services-container .box::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
.services-container .box::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
.services-container .box::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.services-container .box .icon{
    position: relative;
    width: 80px;
    height: 80px;
    color: #fff;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border-radius: 50%;
    font-size: 40px;
    font-weight: 700;
    transition: 0.5s;
}

.services-container .box .icon{
    box-shadow: 0 0 0 0 rgb(41, 171, 226);
    background-color: rgb(41, 171, 226);
}

.services-container .box:hover .icon{
    box-shadow: 0 0 0 400px rgb(41, 171, 226);
}

.services-container .box .content{
    position: relative;
    z-index: 1;
    transition: 0.5s;
    direction: rtl;
}

.services-container .box:hover .content{
    color: #fff;
}