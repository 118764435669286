
.contact-form-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.contact-form{
    width: 1150px;
    padding: 50px 80px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    transition: all 0.5s ease;
}

.contact-form .contact{
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.5s ease;
}

.contact-form .contact .map{
    width: 400px;
    margin-right: 35px;
    animation-duration: 1.5s;
    border: 1px dashed rgb(41, 171, 226);
    padding-top: 20px;
}

.contact-form .contact .map iframe{
    width: 100%;
    height: 300px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.contact-form .contact .form{
    width: 525px;
    transition: all 0.5s ease;
    animation-duration: 1.5s;
}

.contact-form .contact .form input,
.contact-form .contact .form textarea,
.as-input {
    width: 100%;
    padding: 15px 10px;
    border: 1px solid #dadce0;
    border-radius: 5px;
    outline: none;
    margin-bottom: 20px;
    transition: 0.3s;
    background-color: #f8f9fa;
}

.contact-form .contact .form input:focus,
.contact-form .contact .form textarea:focus,
.as-input:focus{
    border: 1px solid rgb(41, 171, 226);
    box-shadow: inset 1px 1px 0 rgb(41, 171, 226),
    inset -1px -1px 0 rgb(41, 171, 226);
}

.contact-form .contact .form textarea{
    height: 90px;
    max-width: 100%;
    resize: none;
}

.contact-form .contact .form .submit-area{
    position: relative;
}

.contact-form .contact .form .submit-area .submit-button{
    display: inline-block;
    width: 125px;
    padding: 10px 20px;
    border-radius: 1px;
    cursor: pointer;
    background-color: rgb(41, 171, 226);
    border: none;
    color: #FFF;
    font-size: 14px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.1s ease;
    text-align: right;
}

.contact-form .contact .form .submit-area .submit-button:hover{
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.25);
}

.contact-form .contact .form .submit-area i{
    position: absolute;
    left: 10px;
    top: 15px;
    color: #FFF;
    font-size: 13px;
}

.contact-info-item{
    text-align: center;
}

.contact-info-item label{
    margin: 0 3px;
    direction: rtl;
}

.contact-info-title{
    margin-bottom: 7px !important;
    font-size: 20px;
    color: rgb(41, 171, 226);
}

.contact-info-item i{
    margin-left: 10px;
}

.contact-info-value{
    margin-bottom: 30px !important;
    color: rgba(41, 171, 226, 0.7);
}


@media (max-width: 1200px){
    .contact-form{
        width: 960px;
        padding: 50px 60px;
    }

    .contact-form .contact .map{
        width: 320px;
    }

    .contact-form .contact .form{
        width: 485px;
    }
}

@media (max-width: 992px){
    .contact-form{
        width: 710px;
        height: auto;
    }

    .contact-form .contact{
        flex-direction: column;
    }

    .contact-form .contact .map{
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
    }

    .contact-form .contact .form{
        width: 100%;
    }
}

@media (max-width: 768px){
    .contact-form{
        width: 100%;
    }
}


.contact-form .contact .form .label{
    background-color: #f8f9fa;
    color: #6b6b6b;
    font-size: 15px;
    font-weight: 300;
    margin: 0 8px;
    padding: 0 5px;
    position: absolute;
    right: 0;
    transition: all 0.5s cubic-bezier(0.50, 1.35, 0.50, 1.35);
    direction: rtl;
}

.contact-form .contact.apply-job-container .form .label {
    right: unset !important;
}

.contact-form .contact .form .email-text,
.contact-form .contact .form .subject-text{
    transform: translateY(-56px);
}

.contact-form .contact .form .help-text{
    transform: translateY(-112px);
}

.contact-form .contact .form input:focus + .label,
.contact-form .contact .form input:valid + .label,
.contact-form .contact .form textarea:focus + .label,
.contact-form .contact .form textarea:valid + .label{
    font-size: 13px;
    font-weight: 600;
    color: rgb(41, 171, 226);
}

.contact-form .contact .form input:focus + .email-text,
.contact-form .contact .form input:valid + .email-text,
.contact-form .contact .form input:focus + .subject-text,
.contact-form .contact .form input:valid + .subject-text{
    transform: translateY(-80px);
}

.contact-form .contact .form textarea:focus + .help-text,
.contact-form .contact .form textarea:valid + .help-text{
    transform: translateY(-123px);
}

.form label{
    display: unset !important;
    margin-bottom: unset !important;
}