
.portfolio-area{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;
}

.portfolio-area .box{
    position: relative;
    width: 350px;
    background-color: #FFF;
    box-shadow: 0 5px 15px rgba(0,0,0,.1);
    border-radius: 4px;
    margin: 20px;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;
    direction: rtl;
    transform: translateY(0px);
    transition: 0.5s;
    cursor: pointer;
}

.portfolio-area .box:hover{
    transform: translateY(-10px);
    transition: 0.5s;
}

.portfolio-area .box .content{
    position: relative;
    z-index: 1;
    transition: 0.5s;
    direction: rtl;
    height: 200px;
    max-height: 200px;
    overflow-y: auto;
}

.portfolio-area .box .content h4{
    font-size: 14px;
    color: rgb(41, 171, 226);
}

.portfolio-area .box img{
    width: 100%;
    height: 250px;
    object-fit: cover;
}